<template>
  <div class="auth">
    <div class="auth__content">
      <form class="auth__form" @submit.prevent="onSignUpFormSubmit">
        <c-heading color="#2D2D2D" as="h2" class="auth__form__title">
          Sign Up
        </c-heading>
        <c-text my="3" fontWeight="500">
          Already have an account?
          <c-link as="router-link" to="/auth/login" fontWeight="600"
            >Sign in</c-link
          >
        </c-text>
        <c-stack mb="2em" :spacing="4" is-inline>
          <c-box w="50%">
            <c-input-group h="60px" size="lg">
              <c-input-left-element class="auth__form__input-icon" h="100%">
                <svg>
                  <use
                    href="./../../assets/icons/icon-name.svg#icon_name"
                  ></use>
                </svg>
              </c-input-left-element>
              <c-input
                borderRadius="10px"
                h="100%"
                type="text"
                class="auth__form__input"
                placeholder="First name"
                v-model="$v.registerForm.firstname.$model"
              />
            </c-input-group>
            <c-text
              color="vc-orange.400"
              fontSize="xs"
              my="1"
              v-if="
                $v.registerForm.firstname.$dirty &&
                !$v.registerForm.firstname.required
              "
            >
              Your first name is required
            </c-text>
            <c-text
              color="vc-orange.400"
              fontSize="xs"
              my="1"
              v-if="
                $v.registerForm.firstname.$dirty &&
                !$v.registerForm.firstname.minLength
              "
            >
              Your first name should be a minimum of 3 characters
            </c-text>
          </c-box>
          <c-box w="50%">
            <c-input-group h="60px" size="lg">
              <c-input-left-element class="auth__form__input-icon" h="100%">
                <svg>
                  <use
                    href="./../../assets/icons/icon-name.svg#icon_name"
                  ></use>
                </svg>
              </c-input-left-element>
              <c-input
                borderRadius="10px"
                h="100%"
                type="text"
                class="auth__form__input"
                placeholder="Last name"
                v-model="$v.registerForm.lastname.$model"
              />
            </c-input-group>
            <c-text
              color="vc-orange.400"
              fontSize="xs"
              my="1"
              v-if="
                $v.registerForm.lastname.$dirty &&
                !$v.registerForm.lastname.required
              "
            >
              Your last name is required
            </c-text>
            <c-text
              color="vc-orange.400"
              fontSize="xs"
              my="1"
              v-if="
                $v.registerForm.lastname.$dirty &&
                !$v.registerForm.lastname.minLength
              "
            >
              Your last name should be a minimum of 3 characters
            </c-text>
          </c-box>
        </c-stack>
        <c-box mb="2em">
          <c-input-group h="60px" size="lg">
            <c-input-left-element class="auth__form__input-icon" h="100%">
              <svg>
                <use href="./../../assets/icons/icon-mail.svg#icon_mail"></use>
              </svg>
            </c-input-left-element>
            <c-input
              borderRadius="10px"
              h="100%"
              type="email"
              class="auth__form__input"
              placeholder="Email"
              v-model="$v.registerForm.email.$model"
            />
          </c-input-group>
          <c-text
            color="vc-orange.400"
            fontSize="xs"
            my="1"
            v-if="
              $v.registerForm.email.$dirty && !$v.registerForm.email.required
            "
          >
            Your Email address is required
          </c-text>
          <c-text
            color="vc-orange.400"
            fontSize="xs"
            my="1"
            v-if="$v.registerForm.email.$dirty && !$v.registerForm.email.email"
          >
            Provide a valid email address
          </c-text>
        </c-box>
        <c-box>
          <c-input-group h="60px" size="lg">
            <c-input-left-element class="auth__form__input-icon" h="100%">
              <svg>
                <use href="./../../assets/icons/icon-lock.svg#icon-lock"></use>
              </svg>
            </c-input-left-element>
            <c-input
              borderRadius="10px"
              h="100%"
              :type="showPassword ? 'text' : 'password'"
              class="auth__form__input"
              placeholder="Password"
              v-model="$v.registerForm.password.$model"
            />
            <c-input-right-element class="auth__form__input-toggle">
              <c-link @click="showPassword = !showPassword">
                {{ showPassword ? 'Hide' : 'Show' }}
              </c-link>
            </c-input-right-element>
          </c-input-group>
          <c-text
            color="vc-orange.400"
            fontSize="xs"
            my="1"
            v-if="
              $v.registerForm.password.$dirty &&
              !$v.registerForm.password.required
            "
          >
            Password is required
          </c-text>
          <c-text
            color="vc-orange.400"
            fontSize="xs"
            my="1"
            v-if="
              $v.registerForm.password.$dirty &&
              !$v.registerForm.password.minLength
            "
          >
            Password should be a minimum of 8 characters
          </c-text>
        </c-box>
        <c-button
          w="100%"
          h="60px"
          borderRadius="lg"
          size="lg"
          variant="solid"
          type="submit"
          class="auth__form__button auth__form__button--primary"
          :disabled="
            $v.registerForm.$invalid || isRegistering || isSigningWithGoogle
          "
        >
          <c-spinner v-if="isRegistering" color="#fff" thickness="3px" />
          <c-text v-else color="#fff">Register </c-text>
        </c-button>
        <c-flex justify="center" mt="4" align="center">
          <c-box mr="3">
            <c-box id="googleBtn" mx="auto" w="max-content"> </c-box>
          </c-box>
          <c-spinner
            v-if="isSigningWithGoogle"
            thickness="2px"
            color="blue.400"
          ></c-spinner>
        </c-flex>
        <!-- <c-link
          fontWeight="500"
          color="#ef5323"
          fontSize="xs"
          as="router-link"
          class="auth__form__social"
          to="/"
        >
          or signup with Gmail
        </c-link> -->

        <c-text mt="10px" fontSize="sm" textAlign="center" color="#495057">
          By clicking the button above you agree to our
          <c-link color="#ef5323" fontWeight="500">Terms of Service</c-link> and
          <c-link color="#ef5323" fontWeight="500">Privacy Policy</c-link>
        </c-text>
      </form>
      <c-flex direction="column">
        <HeroGraphic :image="'signup-graphic.webp'" :position="'right'" />
        <c-text
          w="75%"
          mx="auto"
          textAlign="center"
          fontStyle="italic"
          fontWeight="600"
          color="vc-orange.400"
          :display="{ base: 'none', lg: 'block' }"
        >
          The way to get started is to quit talking and begin doing
        </c-text>
        <c-text
          mt="5"
          mx="auto"
          textAlign="center"
          color="vc-primary.400"
          fontStyle="italic"
          :display="{ base: 'none', lg: 'block' }"
        >
          Walt Disney
        </c-text>
      </c-flex>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { required, email, minLength } from 'vuelidate/lib/validators';
import { register } from '@/services/auth.js';

import config from '@/mixins/config.js';
import googleAuth from '@/mixins/google-auth.js';

import { onLogin } from '@/vue-apollo.js';

import HeroGraphic from './components/HeroGraphic.vue';

export default {
  mixins: [config, googleAuth],
  components: {
    HeroGraphic,
  },
  data() {
    return {
      showPassword: false,
      registerForm: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
      },
      isRegistering: false,
      googleButtonText: 'signup_with',
    };
  },
  validations: {
    registerForm: {
      firstname: {
        required,
        minLength: minLength(3),
      },
      lastname: {
        required,
        minLength: minLength(3),
      },
      email: {
        email,
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    ...mapActions({
      setTempUser: 'auth/setTempUser',
      setUser: 'auth/setUser',
      setToken: 'auth/setToken',
    }),
    onSignUpFormSubmit() {
      this.isRegistering = true;
      const form = { ...this.registerForm };
      register(form)
        .then(() => {
          this.isRegistering = false;
          // eslint-disable-next-line no-unused-vars
          const { password, ...rest } = form;
          this.setTempUser(rest);
          this.$router.push('/auth/verify-email');
          this.$gtag.event('sign_up', { method: 'AuthServer' });
        })
        .catch((e) => {
          const errorMessage = e.response
            ? e.response.data.message
            : 'Please Try again';
          this.isRegistering = false;
          this.$toast({
            title: 'An error occurred.',
            description: errorMessage,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    async onGoogleAuth(data) {
      await this.onSignUp(data);
      this.isSigningWithGoogle = false;
      this.$gtag.event('sign_up', { method: 'Google' });
    },
    async onSignUp(data) {
      this.setToken(data.token);
      this.setUser(data.user);
      await onLogin(this.$apollo.provider.defaultClient, data.token);
      this.getGlobalConfig();
      this.$router.push('/onboarding');
    },
  },
};
</script>

<style lang="scss">
.auth {
  &__left {
    background-image: linear-gradient(
        to right top,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ),
      url('./../../assets/img/auth-left-female.jpg');
  }
}
</style>

import http from '@/helpers/api';

export const register = (data) => {
  return http.post('/users', data);
};

export const updateNewInvite = (data) => {
  return http.patch('/users/change-password', data);
};

export const verifyEmail = (data) => {
  return http.post('/users/verify_email', data);
};

export const resendVerificationEmail = (data) => {
  return http.post('/users/resendVerifyEmail', data);
};

export const authenticateWithGoogle = (data) => {
  return http.post('/users/google-sign-on', data);
};

export const login = (data) => {
  return http.post('/auth/login', data);
};

export const resetPassword = (data) => {
  return http.post('/auth/reset_password', data);
};

export const setPassword = (data) => {
  return http.patch(
    '/auth/set_password',
    { password: data.password },
    { headers: { token: data.resetToken } }
  );
};

export const getUserProfile = () => {
  return http.get('/users/me');
};
